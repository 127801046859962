export enum ErrorTypes {
  UnauthorizedFortnox = 'unauthorizedFortnox',
  FortnoxFailure = 'fortnoxFailure',
  DbFailure = 'dbFailure',
  Auth0Failure = 'auth0Failure',
  InvalidTokenData = 'invalidTokenData',
  InvalidParameters = 'invalidParameters',
  ItemAlreadyExists = 'itemAlreadyExists',
  ValidationError = 'validationError',
}
