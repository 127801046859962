import { Divider, Drawer } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Functional, MenuItem, Resource, Utils, Settings } from './sidebarConfig';
import MenuList from './SidebarMenuList';
import { drawerCssProps } from '../../pages/dashboard/Dashboard';
import { SidebarProfile } from './SidebarProfile';

interface Props {
  isOpen: boolean;
  activeItem: MenuItem;
  setIsOpen: (isOpen: boolean) => void;
  setActiveItem: (item: MenuItem) => void;
}

export const Sidebar = ({ isOpen, activeItem, setIsOpen, setActiveItem }: Props) => {
  const { logout } = useAuth0();

  // Handle itemClick
  const handleItemClick = (item: MenuItem) => {
    if (item.id === 'logout') {
      logout({ logoutParams: { returnTo: window.location.origin } });
      return;
    }

    setIsOpen(true); // automatically expand drawer

    if (!item.submenu) {
      setActiveItem(item);
    }
  };

  return (
    <Drawer
      data-testid="officialMenu"
      anchor="left"
      open={true}
      variant="persistent"
      sx={{
        width: isOpen ? drawerCssProps.openWidth : drawerCssProps.closedWidth,
        transition: '0.3s',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          gap: '10px',
          border: 'none',
          width: isOpen ? drawerCssProps.openWidth : drawerCssProps.closedWidth,
          overflowX: 'hidden',
          transition: '0.3s',
          '&::-webkit-scrollbar': {
            display: 'none', // For Chrome, Safari, and Edge to hide the scrollbar
          },
        },
      }}
      ModalProps={{
        keepMounted: true,
        hideBackdrop: true,
      }}
    >
      {/* Profile Section */}
      <SidebarProfile isOpen={isOpen} setIsOpen={setIsOpen} />

      <Divider sx={{ width: '90%' }} />

      <MenuList
        menuItem={Functional}
        activeItem={activeItem}
        isOpen={isOpen}
        onClickItem={handleItemClick}
      />
      <Divider sx={{ width: '90%' }} />
      <MenuList
        menuItem={Resource}
        activeItem={activeItem}
        isOpen={isOpen}
        onClickItem={handleItemClick}
      />
      <Divider sx={{ width: '90%' }} />
      <MenuList
        menuItem={Utils}
        activeItem={activeItem}
        isOpen={isOpen}
        onClickItem={handleItemClick}
      />
      <Divider sx={{ width: '90%' }} />
      <MenuList
        menuItem={Settings}
        activeItem={activeItem}
        isOpen={isOpen}
        onClickItem={handleItemClick}
      />
    </Drawer>
  );
};
