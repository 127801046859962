import { Outlet, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { providerConfig } from '../../auth/providerConfig';
import AuthLayout from '../layouts/AuthLayout';
import { UserProvider } from '../../contexts/UserContext';

interface AppState {
  returnTo?: string;
}

export const Auth0ProviderWithRedirectCallback = () => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined): void => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...providerConfig}>
      <AuthLayout>
        <UserProvider>
          <Outlet />
        </UserProvider>
      </AuthLayout>
    </Auth0Provider>
  );
};
