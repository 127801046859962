import { Route, createBrowserRouter, createRoutesFromElements, Outlet } from 'react-router-dom';
import { authenticatedNavigationConfig } from './authenticatedRoutes';
import { publicNavigationConfig } from './publicRoutes';
import { Dashboard } from '../../pages/dashboard/Dashboard';
import { Auth0ProviderWithRedirectCallback } from './ProtectedRoute';
import { PublicGlobalModals } from '../modals/PublicGlobalModals';
import { AuthenticatedGlobalModals } from '../modals/AuthenticatedGlobalModals';

const authenticatedRouteElements = authenticatedNavigationConfig.map((route) => (
  <Route key={route.path} path={route.path} lazy={route.lazy} />
));

const publicRouteElements = publicNavigationConfig.map((route) => (
  <Route key={route.path} path={route.path} lazy={route.lazy} />
));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <>
            <PublicGlobalModals />
            <Outlet />
          </>
        }
      >
        {publicRouteElements}
      </Route>
      <Route element={<Auth0ProviderWithRedirectCallback />}>
        <Route
          path="/"
          element={
            <>
              <Dashboard />
              <AuthenticatedGlobalModals />
            </>
          }
        >
          {authenticatedRouteElements}
        </Route>
      </Route>
    </>
  )
);
