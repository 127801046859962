import { LazyRouteFunction, NonIndexRouteObject } from 'react-router-dom';

export type NavigationConfig = {
  path: string;
  lazy?: LazyRouteFunction<NonIndexRouteObject>;
};

export const publicNavigationConfig: NavigationConfig[] = [
  {
    path: '/page/public',
    lazy: async () => ({
      Component: (await import('../../pages/public/PublicPage')).default,
    }),
  },
  {
    path: '/page/public/sign/*',
    lazy: async () => ({
      Component: (await import('../../pages/public/ContractSignPage')).default,
    }),
  },
];
