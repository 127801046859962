import { lazy, Suspense } from 'react';
import { useModal } from '../../contexts/ModalContext';
import { BaseModalLoader } from './BaseModalLoader';

const VehiclesModal = lazy(() => import('../../pages/resources/vehiclesModal/VehiclesModal'));
const ContactModal = lazy(() => import('./contact/ContactModal'));
const EmployeeSetupModal = lazy(() => import('./EmployeeSetupModal'));
const OrganizationSetUpModal = lazy(() => import('./OrganizationSetUpModal'));
const CustomerModal = lazy(() => import('../../pages/customers/CustomerModal'));
const CaseModal = lazy(() => import('../../pages/case/CaseModal'));
const EmployeeModal = lazy(() => import('../../pages/resources/employeeModal/EmployeeModal'));
const OrderModalEdit = lazy(() => import('../../pages/order/orderModal/OrderModalEdit'));
const OrderModalCreate = lazy(() => import('../../pages/order/orderModal/OrderModalCreate'));
const ContractModal = lazy(() => import('../../pages/contracts/contractModal/ContractModal'));
const ContractProductModal = lazy(
  () => import('../../pages/contracts/contractPage/modals/ContractProductModal')
);
const WorkTaskJobModal = lazy(() => import('../../pages/templates/modals/WorkTaskJobModal'));
const WorkTaskAreaModal = lazy(() => import('../../pages/templates/modals/WorkTaskAreaModal'));
const IntervalModal = lazy(() => import('../../pages/intervals/IntervalModal'));
const SupportModal = lazy(() => import('./support/SupportModal'));
const VehicleDeviationModal = lazy(
  () => import('../../pages/resources/vehiclesModal/VehiclesDeviationModal')
);
const SignConfirmationModal = lazy(() => import('../../pages/public/SignConfirmationModal'));

export const AuthenticatedGlobalModals = () => {
  const { modalState } = useModal();

  return (
    <Suspense fallback={<BaseModalLoader />}>
      {modalState?.OrderModalEdit?.open && <OrderModalEdit name="OrderModalEdit" />}
      {modalState?.OrderModalCreate?.open && <OrderModalCreate name="OrderModalCreate" />}
      {modalState?.OfferModal?.open && (
        <ContractModal name="OfferModal" title="Skapa en ny offert" />
      )}
      {modalState?.SignConfirmationModal?.open && (
        <SignConfirmationModal name="SignConfirmationModal" />
      )}
      {modalState?.ProjectModal?.open && (
        <ContractModal name="ProjectModal" title="Skapa en ny offert" />
      )}

      {modalState?.EmployeeModal?.open && <EmployeeModal name="EmployeeModal" />}

      {modalState?.ContractModal?.open && <ContractModal name="ContractModal" />}

      {modalState?.OrgSetup?.open && <OrganizationSetUpModal name="OrgSetup" />}

      {modalState?.EmployeeSetup?.open && <EmployeeSetupModal name="EmployeeSetup" />}

      {modalState?.ContactModal?.open && (
        <ContactModal name="ContactModal" title="Skapa en ny mottagare" />
      )}

      {modalState?.VehiclesModal?.open && <VehiclesModal name="VehiclesModal" />}

      {modalState?.CustomerModal?.open && <CustomerModal name="CustomerModal" />}

      {modalState?.CaseModal?.open && <CaseModal name="CaseModal" />}

      {modalState?.ContractProductModal?.open && (
        <ContractProductModal name="ContractProductModal" />
      )}

      {modalState?.WorkTaskJobModal?.open && <WorkTaskJobModal name="WorkTaskJobModal" />}
      {modalState?.WorkTaskAreaModal?.open && <WorkTaskAreaModal name="WorkTaskAreaModal" />}
      {modalState?.IntervalModal?.open && <IntervalModal name="IntervalModal" />}
      {modalState?.SupportModal?.open && <SupportModal name="SupportModal" />}
      {modalState?.VehicleDeviationModal?.open && (
        <VehicleDeviationModal name="VehicleDeviationModal" />
      )}
    </Suspense>
  );
};
