import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AlertProvider } from '../../contexts/AlertContext';
import { ModalProvider } from '../../contexts/ModalContext';
import { theme } from '../../configs/theme';

interface RootProvidersProps {
  children: React.ReactNode;
}

export const RootLayout = ({ children }: RootProvidersProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
      <AlertProvider>
        <ModalProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </ModalProvider>
      </AlertProvider>
    </LocalizationProvider>
  );
};
