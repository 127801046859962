import { Alert, AlertProps, Container } from '@mui/material';
import { createContext, ReactNode, useContext, useState } from 'react';

/**
 * Interface for the AlertContext, defining the `alert` function.
 */
interface AlertContextProps {
  /**
   * Displays an alert with specified severity and message.
   *
   * @param severity - The severity level of the alert ('error', 'warning', 'info', or 'success').
   * @param message - The message to display in the alert.
   * @param callback - Optional callback function to execute when the alert is dismissed.
   * @param seconds - Optional number of seconds before the alert auto-dismisses (default: 5).
   */
  alert: (
    severity: AlertProps['severity'],
    message?: string,
    callback?: () => void,
    seconds?: number
  ) => void;
}

/**
 * Context for managing alert functionality.
 */
const AlertContext = createContext<AlertContextProps | null>(null);

/**
 * A provider component that wraps its children with an alert management context.
 *
 * @param {React.ReactNode} children - The components that need access to the alert functionality.
 * @returns {React.ReactElement} The `AlertProvider` component.
 *
 * @example
 * <AlertProvider>
 *    <App />
 * </AlertProvider>
 */
export const AlertProvider = ({ children }: { children: ReactNode }) => {
  // State for controlling the visibility of the alert.
  const [display, setDisplay] = useState<boolean>(false);

  // State for the severity of the alert.
  const [severity, setSeverity] = useState<AlertProps['severity']>('info');

  // State for the alert message.
  const [message, setMessage] = useState<string | undefined>('');

  /**
   * Displays an alert with the specified properties.
   *
   * @param severity - The severity level of the alert.
   * @param message - The message to display in the alert.
   * @param callback - Optional callback function to execute when the alert is dismissed.
   * @param seconds - Optional number of seconds before the alert auto-dismisses (default: 5 seconds).
   */
  const alert = (
    severity: AlertProps['severity'] = 'info',
    message?: string,
    callback?: () => void,
    seconds = 5
  ) => {
    setSeverity(severity);
    setMessage(message);
    setDisplay(true);

    // Automatically hide the alert after the specified time.
    setTimeout(() => {
      setDisplay(false);
      if (callback) callback(); // Execute the callback if provided.
    }, seconds * 1000);
  };

  return (
    <AlertContext.Provider value={{ alert }}>
      {children}
      {/* Alert container */}
      <Container
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          zIndex: 1500, // Ensure it appears above other components like modals
          display: 'flex',
          justifyContent: 'center',
          pointerEvents: 'none', // Prevent interaction issues
        }}
      >
        {/* Render the alert if display is true */}
        {display && (
          <Alert variant="filled" severity={severity}>
            {message}
          </Alert>
        )}
      </Container>
    </AlertContext.Provider>
  );
};

/**
 * Custom hook for accessing the alert context.
 *
 * @returns {AlertContextProps} The `alert` function from the context.
 * @throws Error if the hook is used outside of `AlertProvider`.
 *
 * @example
 * const { alert } = useAlert();
 * alert('success', 'Operation completed successfully');
 */
export const useAlert = () => {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('useAlert should be used inside AlertProvider');
  }

  return context;
};
