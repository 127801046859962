import { lazy, Suspense } from 'react';
import { useModal } from '../../contexts/ModalContext';
import { BaseModalLoader } from './BaseModalLoader';

const SignConfirmationModal = lazy(() => import('../../pages/public/SignConfirmationModal'));

export const PublicGlobalModals = () => {
  const { modalState } = useModal();

  return (
    <Suspense fallback={<BaseModalLoader />}>
      {modalState?.SignConfirmationModal?.open && (
        <SignConfirmationModal name="SignConfirmationModal" />
      )}
    </Suspense>
  );
};
