import { Box, LinearProgress, styled, Typography } from '@mui/material';

export const FortnoxLoader = () => {
  return (
    <FortnoxLoaderContainer>
      <LinearProgress color="inherit" />

      <FortnoxLoaderText>
        <Typography sx={{ margin: '0' }}>
          <strong>Ladda inte om sidan!</strong>
        </Typography>

        <Typography sx={{ margin: '0' }}>
          Läser in data från fortnox, detta tar några minuter
        </Typography>
      </FortnoxLoaderText>
    </FortnoxLoaderContainer>
  );
};

const FortnoxLoaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  position: 'fixed',
  top: '5px',
  right: '5px',
  width: '25%',
  zIndex: 9999,
  padding: '10px',
  background: theme.palette.background.default,
  borderRadius: '8px',
  color: theme.palette.customColors.color1,
  boxShadow: 'inset 0px 0px 2px rgba(0,0,0,0.5)',
}));

const FortnoxLoaderText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.primary,
}));
