import { BottomNavigation, BottomNavigationAction, Box, styled } from '@mui/material';
import { useState } from 'react';
import { CalendarMonth, WorkHistory } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const BottomBar = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  return (
    <Box data-testid="workersMenu">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationActionStyled
          onClick={() => navigate('/employee/schedule')}
          icon={<CalendarMonth />}
        />
        <BottomNavigationActionStyled
          onClick={() => navigate('/employee/reportAbsence')}
          icon={<WorkHistory />}
        />
      </BottomNavigation>
    </Box>
  );
};
const BottomNavigationActionStyled = styled(BottomNavigationAction)(({ theme }) => ({
  color: theme.palette.grey[400],
  '&.Mui-selected': {
    color: theme.palette.customColors.color1,
  },
}));
