import { styled } from '@mui/material/styles';
import { Box, Container, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useModal } from '../../contexts/ModalContext';
import { ReactNode } from 'react';

export interface BaseModalProps {
  title?: string;
  name: string;
  children?: ReactNode;
  showCloseButton?: boolean;
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const BaseModal = ({
  title,
  name,
  children,
  showCloseButton = true,
  isLoading = false,
  size = 'large',
}: BaseModalProps) => {
  const { modalState, close } = useModal();
  const modalData = modalState[name] || { open: false, payload: null };

  const handleOnClose = () => {
    close(name);
  };

  return (
    <ModalContainer open={modalData.open}>
      <ModalView size={size}>
        <ModalContent maxWidth={false}>
          <ModalHeader>
            {isLoading ? null : <ModalTitle>{title}</ModalTitle>}
            {showCloseButton && (
              <FlexRowBox>
                <IconButton onClick={handleOnClose}>
                  <Close sx={{ width: 40, height: 40 }} />
                </IconButton>
              </FlexRowBox>
            )}
          </ModalHeader>

          <ContentWrapper>{children}</ContentWrapper>
        </ModalContent>
      </ModalView>
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalView = styled(Box)<{ size?: 'small' | 'medium' | 'large' }>(
  ({ theme, size = 'large' }) => ({
    background: theme.palette.background.default,
    width: size === 'small' ? '40vw' : size === 'medium' ? '60vw' : '75vw',
    height: size === 'small' ? 'auto' : size === 'medium' ? '75vh' : '95vh',
    maxHeight: '95vh',
    borderRadius: '16px',
    padding: '30px',
    boxShadow: theme.shadows[5],
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: size === 'small' ? 'auto' : '50vh',
  })
);

const ModalContent = styled(Container)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  gap: '1rem',
}));

const ContentWrapper = styled(Box)(() => ({
  flex: 1,
  overflowY: 'auto',
  scrollbarWidth: 'none',
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: theme.palette.customColors.color1,
  fontSize: 35,
}));

const FlexRowBox = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
}));

const ModalHeader = styled(Box)((title) => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  justifyContent: `${title ? 'space-between' : 'end'}`,
  width: '100%',
}));
