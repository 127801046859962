import { useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { error } from '../types/error';
import { useHandleError } from './useHandleError';
import { ErrorMessages } from '../types/ErrorMessages';

/**
 * A custom hook for making API calls using Axios.
 *
 * @template T - The type of the response data.
 * @param {string} url - The endpoint to call (relative to the API host).
 * @param {AxiosRequestConfig} [defaultOptions] - Default Axios request configuration.
 * @returns {object} An object containing the API response data, loading state, error state, and a `callAPI` function.
 */
export default function useAPI<T>(defaultOptions?: AxiosRequestConfig) {
  const { handleError } = useHandleError();

  const { getAccessTokenSilently } = useAuth0();

  // Base API host URL (configured via environment variables)
  const host = import.meta.env.VITE_REACT_APP_API_URL;

  // State for storing the API response data
  const [data, setData] = useState<T | null>(null);

  // State for tracking the loading status
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // State for tracking any errors that occur during the API call
  const [error, setError] = useState<error | null | AxiosError>(null);

  /**
   * Function to make an API call to the given URL.
   *
   * @param {object} [body] - Optional body to send with the request.
   * @param {AxiosRequestConfig} [overrideOptions] - Optional additional Axios configuration.
   */
  const callAPI = async (
    url: string,
    body?: object,
    errorMessages?: ErrorMessages,
    overrideOptions?: AxiosRequestConfig
  ) => {
    setData(null); // reset the data when calling

    const token = await getAccessTokenSilently();

    setIsLoading(true); // Indicate the request is in progress
    try {
      const response: AxiosResponse<T> = await axios({
        ...defaultOptions,
        ...overrideOptions,
        url: host + url,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
          ...defaultOptions?.headers,
          ...overrideOptions?.headers, // Merge headers dynamically
        },
      });
      setData(response.data); // Store the response data

      if (defaultOptions?.method === 'POST' || defaultOptions?.method === 'PUT') {
        return response.data;
      }
    } catch (e: any) {
      const error: error = e;

      handleError(error, errorMessages);
      setError(error); // Store the error if the request fails
      throw error as AxiosError;
    } finally {
      setIsLoading(false); // Indicate the request is complete
    }
  };

  // Return the API response data, loading state, error state, and the API call function
  return { data, isLoading, error, callAPI };
}
