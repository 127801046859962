import { useEffect, useState } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import { Functional } from '../../components/sidebar/sidebarConfig';
import { MenuItem as SidebarMenuItem } from '../../components/sidebar/sidebarConfig';
import { Outlet, useLocation } from 'react-router-dom';
import { Sidebar } from '../../components/sidebar/Sidebar';
import { useModal } from '../../contexts/ModalContext';
import useAPI from '../../hooks/useAPI';
import { theme } from '../../configs/theme';

export const drawerCssProps = {
  openWidth: 240,
  closedWidth: 60,
};

export const OfficialView = () => {
  const { callAPI } = useAPI();
  const location = useLocation();
  const { open: openModal } = useModal();
  const [activeItem, setActiveItem] = useState<SidebarMenuItem>(
    Functional[0].submenu ? Functional[0].submenu[0] : Functional[0]
  );
  const [isOpen, setIsOpen] = useState(true);
  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    // connect websocket to fortnox
    callAPI('/api/v1/websocket/connect');
  }, []);

  const handleHelpClick = (event: React.MouseEvent<HTMLElement>) => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setHelpAnchorEl(null);
  };

  const handleSupportClick = () => {
    handleHelpClose();
    openModal('SupportModal');
  };

  return (
    <>
      <Sidebar
        activeItem={activeItem}
        isOpen={isOpen}
        setActiveItem={setActiveItem}
        setIsOpen={setIsOpen}
      />

      <IconButton
        onClick={handleHelpClick}
        sx={{
          position: 'fixed',
          top: 16,
          right: 16,
          zIndex: 1200,
          color: theme.palette.customColors.color1,
          '&:hover': { backgroundColor: 'rgba(84, 138, 115, 0.04)' },
        }}
      >
        <HelpOutlineOutlined
          sx={{
            fontSize: 32,
            color: theme.palette.customColors.color1,
          }}
        />
      </IconButton>

      <Menu
        anchorEl={helpAnchorEl}
        open={Boolean(helpAnchorEl)}
        onClose={handleHelpClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleHelpClose}>+46 12 345 67 89</MenuItem>
        <MenuItem onClick={handleSupportClick}>Kontakta Support</MenuItem>
      </Menu>

      <Box
        sx={{
          display: 'flex',
          width: isOpen
            ? `calc(100% - ${drawerCssProps.openWidth}px)`
            : `calc(100% - ${drawerCssProps.closedWidth}px)`,
          flexFlow: 'column',
          padding: '20px 50px 20px 50px',
          marginLeft: isOpen ? `${drawerCssProps.openWidth}px` : `${drawerCssProps.closedWidth}px`,
          flexGrow: 1,
          ml: `${isOpen ? drawerCssProps.openWidth : drawerCssProps.closedWidth}px`,
          transition: (theme) =>
            theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {!location.pathname.startsWith('/employee') ? (
          <Outlet />
        ) : (
          <div>Du saknar access för denna vy</div>
        )}
      </Box>
    </>
  );
};
