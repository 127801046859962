import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { Employee } from '../models/Employee';
import useAPI from '../hooks/useAPI';
import { useModal } from './ModalContext';
import { Organization } from '../models/Organization';
import { FortnoxLoader } from '../components/common/FortnoxLoader';
import { FortnoxIntegrationType } from '../types/backendTypes/FortnoxIntegrationType';
import { PersonelType } from '../types/backendTypes/PersonelType';
import { useNavigate } from 'react-router-dom';

// Define the context value type
interface UserContextType {
  user: Employee | null;
  loading: boolean;
  error: string | null;
  refreshUser: () => Promise<void>;
  fortnoxBaseDataIsLoading: boolean;
  fortnoxSalaryDataIsLoading: boolean;
  isFortnoxBaseConnected: boolean;
  isFortnoxSalaryConnected: boolean;
}

// Create the context
export const UserContext = createContext<UserContextType | undefined>(undefined);

// Custom hook to use the UserContext
export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

// Provider component
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { data: organisationData, callAPI: checkOrgAPI } = useAPI<Organization>();

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [user, setUser] = useState<Employee | null>(null);
  const [isFortnoxBaseConnected, setIsFortnoxBaseConnected] = useState<boolean>(false);
  const [isFortnoxSalaryConnected, setIsFortnoxSalaryConnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const callbackUri = `${window.location.protocol}%2F%2F${window.location.host}`;
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get('code');
  const { callAPI: setupFortnoxBaseTokensInDb } = useAPI<void>();
  const { callAPI: setupFortnoxSalaryTokensInDb } = useAPI<void>();
  const {
    callAPI: setupAllFortnoxBaseDataInDb,
    isLoading: fortnoxBaseDataIsLoading,
    data: fortnoxBaseData,
  } = useAPI<void>();
  const {
    callAPI: setupAllFortnoxSalaryDataInDb,
    isLoading: fortnoxSalaryDataIsLoading,
    data: fortnoxSalaryData,
  } = useAPI<void>();

  const host = import.meta.env.VITE_REACT_APP_API_URL;

  const { open } = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof organisationData !== 'object') {
      open('OrgSetup');
    }

    if (organisationData) {
      setIsFortnoxBaseConnected(organisationData.isFortnoxBaseConnected);
      setIsFortnoxSalaryConnected(organisationData.isFortnoxSalaryConnected);
      fetchUser();
    }
  }, [organisationData]);

  useEffect(() => {
    const chosenFortnoxIntegration = sessionStorage.getItem('chosenFortnoxIntegration');

    // get fortnox data for "base" aka articles, customers etc
    if (chosenFortnoxIntegration === FortnoxIntegrationType.Base && user && organisationData) {
      sessionStorage.setItem('chosenFortnoxIntegration', '');

      // get Fortnox tokens with auth code
      setupFortnoxBaseTokensInDb(
        `/api/v1/fortnoxToken/authCode/${authCode}/callbackUri/${callbackUri}/integrationType/base`
      ).then(() => {
        // get all data after the Fortnox tokens are fetched with the auth code
        setupAllFortnoxBaseDataInDb(`/api/v1/allData/integrationType/base`);
      });
      // get fortnox data for "salary" aka employees etc
    } else if (
      chosenFortnoxIntegration === FortnoxIntegrationType.Salary &&
      user &&
      organisationData
    ) {
      sessionStorage.setItem('chosenFortnoxIntegration', '');

      // get Fortnox tokens with auth code
      setupFortnoxSalaryTokensInDb(
        `/api/v1/fortnoxToken/authCode/${authCode}/callbackUri/${callbackUri}/integrationType/salary`
      ).then(() => {
        // get all data after the Fortnox tokens are fetched with the auth code
        setupAllFortnoxSalaryDataInDb(`/api/v1/allData/integrationType/salary`);
      });
    }
  }, [organisationData, user, authCode]);

  // Function to fetch the current logged-in user
  const fetchUser = async () => {
    const token = await getAccessTokenSilently();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${host}/api/v1/user`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user');
      }

      if (response.status === 204) {
        open('EmployeeSetup');
      }

      const data: Employee = await response.json();

      setUser(data);

      if (data.personelType === PersonelType.ARB) {
        navigate('/employee/schedule');
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message || 'Unknown error');
        setUser(null);
      }
    } finally {
      setLoading(false);
    }
  };

  // Refresh user's function
  const refreshUser = async () => {
    await fetchUser();
  };

  // Fetch user on initial load
  useEffect(() => {
    if (isAuthenticated) {
      checkOrgAPI('/api/v1/organization');
    }
  }, [fortnoxBaseData, fortnoxSalaryData, isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        error,
        refreshUser,
        fortnoxBaseDataIsLoading,
        fortnoxSalaryDataIsLoading,
        isFortnoxBaseConnected,
        isFortnoxSalaryConnected,
      }}
    >
      <>
        {(fortnoxBaseDataIsLoading || fortnoxSalaryDataIsLoading) && <FortnoxLoader />}
        {children}
      </>
    </UserContext.Provider>
  );
};
