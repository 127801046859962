import {
  Apps,
  BusinessCenter,
  CalendarMonth,
  Category,
  Checklist,
  ControlCamera,
  Diversity3,
  ListAlt,
  Logout,
  PendingActions,
  Settings as SettingIcon,
  Speed,
  TextSnippet,
  Wallet,
} from '@mui/icons-material';
import { ReactNode } from 'react';

/**
 * Defines the structure of a menu item, including its title, route, icon, and optional submenu.
 */
export interface MenuItem {
  /**
   * The title of the menu item.
   */
  id?: string;
  /**
   * The title of the menu item.
   */
  title: string;

  /**
   * The path to navigate to when the menu item is clicked.
   */
  to?: string;

  /**
   * An optional icon for the menu item.
   */
  icon?: ReactNode;

  /**
   * An optional list of submenu items.
   */
  submenu?: MenuItem[];
}

// Menu structure with submenus and icons
const Functional: MenuItem[] = [
  {
    title: 'Dashboard',
    to: '/',
    icon: <Speed />,
    submenu: [
      { title: 'Översikt', to: '/dashboard/overview' },
      { title: 'Insikter', to: '/dashboard/insights' },
    ],
  },
  { title: 'Offert', to: '/contracts', icon: <Wallet /> },
  { title: 'Projekt', to: '/project', icon: <ListAlt /> },
  { title: 'Order', to: '/orders', icon: <CalendarMonth /> },
  { title: 'Resursplanering', to: '/resource-planning/schedule', icon: <Apps /> },
  {
    title: 'Tidrapportering',
    to: '/timereport',
    icon: <PendingActions />,
  },
  {
    title: 'Export',
    icon: <Checklist />,
    submenu: [
      { title: 'Fakturaunderlag', to: '/export/invoice' },
      { title: 'Löneunderlag', to: '/export/salary' },
    ],
  },
  {
    title: 'Ärendehantering',
    to: '/case/management',
    icon: <TextSnippet />,
  },
];

// Resource Menu
const Resource: MenuItem[] = [
  {
    title: 'Artiklar',
    icon: <Category />,
    submenu: [
      { title: 'Produkter', to: '/articles/products' },
      { title: 'Tjänster', to: '/articles/services' },
    ],
  },
  {
    title: 'Kunder',
    icon: <BusinessCenter />,
    submenu: [
      { title: 'Företag', to: '/customer/company' },
      { title: 'Privat', to: '/customer/private' },
    ],
  },
  {
    title: 'Resurser',
    icon: <Diversity3 />,
    submenu: [
      {
        title: 'Medarbetare',
        to: '/resources/personnel',
      },
      { title: 'Fordon', to: '/resources/vehicles' },
    ],
  },
];

// Utility Menu
const Utils: MenuItem[] = [
  {
    title: 'Mallar',
    icon: <ControlCamera />,
    submenu: [{ title: 'Arbetsbeskrivning', to: '/templates/worktasks' }],
  },
];

// Settings Menu
const Settings: MenuItem[] = [
  {
    title: 'Inställningar',
    icon: <SettingIcon />,
    submenu: [
      {
        title: 'Integrationer',
        to: '/settings/integrations',
      },
      {
        title: 'Anställningsschema',
        to: '/settings/employmentSchedule',
      },
    ],
  },
  { id: 'logout', title: 'Logga ut', to: '', icon: <Logout /> },
];

export { Functional, Resource, Utils, Settings };
