import { useAlert } from '../contexts/AlertContext';
import { ErrorTypes } from '../types/backendResponses/ErrorTypes';
import { error } from '../types/error';
import { ErrorMessages } from '../types/ErrorMessages';

export const useHandleError = () => {
  const { alert } = useAlert();

  const handleError = (error: error, errorMessages: ErrorMessages | undefined) => {
    switch (error?.response?.data?.status) {
      case ErrorTypes.ItemAlreadyExists:
        alert('error', errorMessages?.alreadyExistingItem);
        return;
      case ErrorTypes.UnauthorizedFortnox:
        alert(
          'error',
          'du behöver godkänna fortnox behörigheten. Gå till "Inställningar" och godkänn behörighet.'
        );
        return;
      default:
        alert('error', 'Något gick fel i vårt system');
    }
  };
  return {
    handleError,
  };
};
