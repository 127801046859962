import { useAuth0 } from '@auth0/auth0-react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { drawerCssProps } from '../../pages/dashboard/Dashboard';
import { Close } from '@mui/icons-material';
import { useUser } from '../../contexts/UserContext';
import { SidebarProfileSkeleton } from './SidebarProfileSkeleton';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const SidebarProfile = ({ isOpen, setIsOpen }: Props) => {
  const { user, loading } = useUser();
  const { user: auth0User } = useAuth0();
  const theme = useTheme();

  // Handle toggle drawer
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return <SidebarProfileSkeleton />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        width: '100%',
        minWidth: drawerCssProps.openWidth,
        gap: '1rem',
        paddingLeft: '8px',
        backgroundColor: '#FFF',
        position: 'sticky',
        top: 0,
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          borderWidth: '2px',
          border: 'solid',
          borderRadius: 50,
          Maxheight: 40,
          Maxwidth: 40,
          borderColor: theme.palette.customColors.color1,
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={toggleDrawer}
      >
        <Box
          component="img"
          alt="profilbild"
          sx={{
            height: 35,
            width: 35,
            padding: '1px',
            borderRadius: 50,
            background: '#FFF',
            '&:hover': { cursor: 'pointer' },
          }}
          src={auth0User?.picture}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '150px' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user?.organization?.displayName}
        </Typography>
      </Box>

      <IconButton
        onClick={toggleDrawer}
        sx={{ height: 50, width: 50, margin: '10px 0px 0px 50px', opacity: 0 }}
      >
        <Close />
      </IconButton>
    </Box>
  );
};
