import { Box, CircularProgress } from '@mui/material';
import { theme } from '../../configs/theme';

interface Props {
  width?: string;
}

export const FullPageSpinner = ({ width = '100svw' }: Props) => {
  return (
    <Box
      flexDirection={'column'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        height: '100svh',
        width: width,
      }}
    >
      <CircularProgress sx={{ color: theme.palette.customColors.color1 }} size="5rem" />
    </Box>
  );
};
