import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    background: {
      default: '#f4f4f4',
      paper: '#ffffff',
    },
    customColors: {
      color1: '#548A73',
      color2: '#E4EED4',
      color3: '#F7FAFD',
      color4: '#C1666B',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          backgroundColor: '#548A73',
          color: 'white',
          fontWeight: 'bold',
          padding: '10px 15px 10px 15px',
        },
      },
    },
  },
});
