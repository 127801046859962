import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { MenuItem } from './sidebarConfig';

interface Props {
  children: ReactNode;
  item: MenuItem;
  onClickItem: (item: MenuItem) => void;
}

export const SidebarLink = ({ children, item, onClickItem }: Props) => {
  return (
    <>
      {item.to ? (
        <Link
          to={item.to}
          onClick={() => onClickItem(item)}
          style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', width: '100%' }}
        >
          {children}
        </Link>
      ) : (
        <div
          onClick={() => onClickItem(item)}
          style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', width: '100%' }}
        >
          {children}
        </div>
      )}
    </>
  );
};
