import { FullPageSpinner } from '../common/FullPageSpinner';
import { BaseModal } from './BaseModal';

export const BaseModalLoader = () => {
  return (
    <BaseModal name="BaseModalLoader">
      <FullPageSpinner />
    </BaseModal>
  );
};
