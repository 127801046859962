import { useEffect, useState } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import 'dayjs/locale/sv';
import { router } from './components/navigation/AppRouter';
import { RootLayout } from './components/layouts/RootLayout';
import { FullPageSpinner } from './components/common/FullPageSpinner';

const App = () => {
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    // loader to prevent Auth0 from rendering before the
    // state is deleted that is send from Fortnox.
    // The state is overwritten by fortnox since Auth0 already sets the state
    // of the authentication flow.
    setIsAuthLoading(true);

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const queryObj: Record<string, string> = {};
    params.forEach((value, key) => {
      queryObj[key] = value;
    });
    // The value "fortnoxAuthState" is set by us in the callbackurl (inside the function "createFortnoxRedirectUri", used on "Settings"-page).
    // "fortnoxAuthState" is removed in order for the "state" to not be overridden på fortnox, since Auth0 needs their "state" value.
    if (queryObj.state === 'fortnoxAuthState') {
      url.searchParams.delete('state');
      window.history.replaceState({}, '', url.toString());
    }
    setIsAuthLoading(false);
  }, []);

  return (
    <RootLayout>
      {!isAuthLoading ? (
        <RouterProvider
          router={router}
          fallbackElement={<FullPageSpinner />}
          future={{
            v7_startTransition: true,
          }}
        />
      ) : (
        <FullPageSpinner />
      )}
    </RootLayout>
  );
};

export default App;
