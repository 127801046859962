import { Box, Skeleton } from '@mui/material';
import { drawerCssProps } from '../../pages/dashboard/Dashboard';

export const SidebarProfileSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        width: '100%',
        minWidth: drawerCssProps.openWidth,
        gap: '10px',
      }}
    >
      <Skeleton
        variant="circular"
        width={40}
        height={40}
        sx={{
          height: 45,
          width: 45,
          padding: '1px',
          margin: '10px',
          '&:hover': { cursor: 'pointer' },
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
    </Box>
  );
};
