const domain = import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN || '';
const clientId = import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID || '';
const audience = import.meta.env.VITE_REACT_APP_AUTH0_AUDIENCE || '';

export const providerConfig = {
  domain: domain,
  clientId: clientId,
  authorizationParams: {
    audience: audience,
    redirect_uri: window.location.origin,
  },
};
