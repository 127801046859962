import { useAuth0 } from '@auth0/auth0-react';
import { ErrorPageAuth } from '../../pages/error/ErrorPageAuth';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  if (location.pathname.startsWith('/public')) {
    return <>{children}</>;
  }

  if (error) {
    return <ErrorPageAuth error={error} />;
  }

  if (inviteMatches && orgMatches) {
    loginWithRedirect({
      authorizationParams: {
        organization: orgMatches[1],
        invitation: inviteMatches[1],
      },
    });
    return null;
  }

  if (!isAuthenticated && !error && !isLoading) {
    loginWithRedirect();
    return null;
  }

  return <>{children}</>;
};

export default AuthLayout;
