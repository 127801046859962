import { Box, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { BottomBar } from '../../components/bottomBar/BottomBar';
import { theme } from '../../configs/theme';
import { useAuth0 } from '@auth0/auth0-react';

export const EmployeeView = () => {
  const { user: auth0User } = useAuth0();

  return (
    <>
      {location.pathname.startsWith('/employee') ? (
        <Box
          height={'100vh'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <Box padding={'1rem'} height={'100%'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box
                width={'40px'}
                height={'40px'}
                display={'flex'}
                borderRadius={'50%'}
                sx={{ background: theme.palette.customColors.color1 }}
              >
                <img src="../../../public/MicroniqIconWhite.png" alt="microniq icon" />
              </Box>
              <Box
                width={'40px'}
                height={'40px'}
                display={'flex'}
                borderRadius={'50%'}
                sx={{ background: theme.palette.customColors.color1 }}
              >
                <Img src={auth0User?.picture} alt="user initials" />
              </Box>
            </Box>
            <Outlet />
          </Box>
          <BottomBar />
        </Box>
      ) : (
        <div>Du saknar access för denna vy</div>
      )}
    </>
  );
};

const Img = styled('img')({
  borderRadius: '50%',
});
