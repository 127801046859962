import { PersonelType } from '../../types/backendTypes/PersonelType';
import { useUser } from '../../contexts/UserContext';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import useAPI from '../../hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { EmployeeView } from './EmployeeView';
import { OfficialView } from './OfficialView';
import { useEffect } from 'react';

export const drawerCssProps = {
  openWidth: 240,
  closedWidth: 60,
};

export const Dashboard = () => {
  const { isAuthenticated } = useAuth0();

  const { callAPI } = useAPI();
  const { user } = useUser();
  useEffect(() => {
    // connect websocket to fortnox
    if (isAuthenticated) {
      callAPI('/api/v1/websocket/connect');
    }
  }, [isAuthenticated]);

  if (!user) {
    return <FullPageSpinner />;
  }

  if (user && user.personelType === PersonelType.ARB) {
    return <EmployeeView />;
  }

  if (user && user.personelType === PersonelType.TJM) {
    return <OfficialView />;
  }
};
