import { LazyRouteFunction, NonIndexRouteObject } from 'react-router-dom';

export type NavigationConfig = {
  path: string;
  lazy?: LazyRouteFunction<NonIndexRouteObject>;
};

export const authenticatedNavigationConfig: NavigationConfig[] = [
  // official routes
  {
    path: '/',
    lazy: async () => ({
      Component: (await import('../../pages/dashboard/Overview')).default,
    }),
  },
  {
    path: '/dashboard/overview',
    lazy: async () => ({
      Component: (await import('../../pages/dashboard/Overview')).default,
    }),
  },
  {
    path: '/dashboard/insights',
    lazy: async () => ({
      Component: (await import('../../pages/dashboard/Insights')).default,
    }),
  },
  {
    path: '/contracts',
    lazy: async () => ({
      Component: (await import('../../pages/contracts/Contracts')).default,
    }),
  },
  {
    path: '/contract/:contractId',
    lazy: async () => ({
      Component: (await import('../../pages/contracts/Contract')).default,
    }),
  },
  {
    path: '/project',
    lazy: async () => ({
      Component: (await import('../../pages/contracts/Project')).default,
    }),
  },
  {
    path: '/project/:projectId',
    lazy: async () => ({
      Component: (await import('../../pages/contracts/Project')).default,
    }),
  },
  {
    path: '/orders',
    lazy: async () => ({
      Component: (await import('../../pages/order/Orders')).default,
    }),
  },
  {
    path: '/order/:orderId',
    lazy: async () => ({
      Component: (await import('../../pages/order/Order')).default,
    }),
  },
  {
    path: '/order/:orderId/interval/:intervalId',
    lazy: async () => ({
      Component: (await import('../../pages/intervals/Interval')).default,
    }),
  },
  {
    path: '/timereport',
    lazy: async () => ({
      Component: (await import('../../pages/timereport/TimeReport')).default,
    }),
  },
  {
    path: '/export/invoice',
    lazy: async () => ({
      Component: (await import('../../pages/invoice/Invoice')).default,
    }),
  },
  {
    path: '/export/salary',
    lazy: async () => ({
      Component: (await import('../../pages/salary/Salary')).default,
    }),
  },
  {
    path: '/case/management',
    lazy: async () => ({
      Component: (await import('../../pages/case/CaseManagement')).default,
    }),
  },

  {
    path: '/articles/products',
    lazy: async () => ({
      Component: (await import('../../pages/articles/Products')).default,
    }),
  },
  {
    path: '/articles/services',
    lazy: async () => ({
      Component: (await import('../../pages/articles/Services')).default,
    }),
  },
  {
    path: '/customer/company',
    lazy: async () => ({
      Component: (await import('../../pages/customers/Companies')).default,
    }),
  },
  {
    path: '/customer/company/:companyId',
    lazy: async () => ({
      Component: (await import('../../pages/customers/Company')).default,
    }),
  },
  {
    path: '/customer/private',
    lazy: async () => ({
      Component: (await import('../../pages/customers/Individuals')).default,
    }),
  },
  {
    path: '/customer/private/:customerId',
    lazy: async () => ({
      Component: (await import('../../pages/customers/Individual')).default,
    }),
  },
  {
    path: '/resources/personnel',
    lazy: async () => ({
      Component: (await import('../../pages/resources/Employees')).default,
    }),
  },
  {
    path: '/resources/personnel/:employeeId',
    lazy: async () => ({
      Component: (await import('../../pages/resources/EmployeePage')).default,
    }),
  },
  {
    path: '/resources/vehicles',
    lazy: async () => ({
      Component: (await import('../../pages/resources/Vehicles')).default,
    }),
  },
  {
    path: '/resources/vehicles/:vehicleId',
    lazy: async () => ({
      Component: (await import('../../pages/resources/Vehicle')).default,
    }),
  },
  {
    path: '/templates/worktasks',
    lazy: async () => ({
      Component: (await import('../../pages/templates/workTasks/WorkTasks')).default,
    }),
  },
  {
    path: '/settings/integrations',
    lazy: async () => ({
      Component: (await import('../../pages/settings/Settings')).default,
    }),
  },
  {
    path: '/settings/employmentSchedule',
    lazy: async () => ({
      Component: (await import('../../pages/settings/EmploymentSchedule')).default,
    }),
  },
  //employee routes
  {
    path: '/employee/reportAbsence',
    lazy: async () => ({
      Component: (await import('../../pages/absence/Absence')).default,
    }),
  },
  {
    path: '/employee/schedule',
    lazy: async () => ({
      Component: (await import('../../pages/workingSchedule/WorkingSchedule')).default,
    }),
  },
  {
    path: '/resource-planning/schedule',
    lazy: async () => ({
      Component: (await import('../../pages/resourcePlanning/ResourcePlanningSchedule')).default,
    }),
  },
  {
    path: '/resource-planning/schedule/data',
    lazy: async () => ({
      Component: (await import('../../pages/resourcePlanning/ResourcePlanningScheduleData'))
        .default,
    }),
  },
];
